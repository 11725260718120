// import { courseUsers } from 'API/course'
import { getActivityLog } from 'API/user'
import { navigate, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React from 'react'
import { useState } from 'react'
import { PromiseType } from 'utility-types'
import { RepoMap } from 'components/RepoMap'
// import { UserType } from 'types/UserAdditionMetaData'

const People: React.FC<PageProps> = ({ params: { id: course_id, user_id } }) => {
	const { ShowDetails, viewAs, stable } = useGetCourseDetails(course_id)
	const [activities, setActivities] = useState<PromiseType<ReturnType<typeof getActivityLog>>>()

	React.useEffect(() => {
		if (stable && viewAs === 'Student') navigate('/404')
		else if (viewAs === 'Teacher') {
			const run = async () => {
				const activity = await getActivityLog({
					student_id: user_id,
					course_id,
					asTeacher: true,
				})
				if (activity) {
					setActivities(activity)
				}
			}
			run()
		}
	}, [viewAs, stable])

	React.useEffect(() => {
		console.log(user_id)
	}, [user_id])

	return (
		<>
			{ShowDetails}
			<div className='p2'>
				<h2 className='py-2 '>
					{activities?.[0]?.user?.first_name} {activities?.[0]?.user?.last_name}
				</h2>
				<table className='table'>
					<thead>
						<tr>
							<th scope='col'>#</th>
							<th scope='col'>Activity Name</th>
							<th scope='col'>Activity Type</th>
							<th scope='col'>First Opened At</th>
							<th scope='col'>Last Opened At</th>
							<th scope='col'>Submitted At</th>
						</tr>
					</thead>
					<tbody>
						{activities?.map((elem, index) => {
							return (
								<>
									<tr>
										<th scope='row'>{index + 1}</th>
										<td>{elem.asset?.name}</td>
										<td>{(RepoMap as any)?.[elem.asset?.__t]?.toUpperCase()} </td>
										<td>{elem.createdAt ? new Date(elem?.createdAt).toLocaleString() : ''}</td>
										<td>{elem.updatedAt ? new Date(elem.updatedAt).toLocaleString() : ''}</td>
										<td>{elem?.submitted_at ? new Date(elem?.submitted_at).toLocaleString() : ''}</td>
									</tr>
								</>
							)
						})}
					</tbody>
				</table>
			</div>
		</>
	)
}

export default People
